/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onInitialClientRender = () => {
  const h = document.createElement('script');
  h.type = 'text/javascript';
  h.src = 'https://tickets.citysprings.com/createsandysprings/website/scripts/integrate.js';

  const p = document.createElement('script');
  p.type = 'text/javascript';
  p.src = 'https://webcomponents.spektrix.com/stable/webcomponents-loader.js';

  const w = document.createElement('script');
  w.type = 'text/javascript';
  w.dataset.components = 'spektrix-donate,spektrix-login-status,spektrix-basket-summary,spektrix-merchandise';
  w.src = 'https://webcomponents.spektrix.com/stable/spektrix-component-loader.js';
  w.async = true;

  const headTag = document.getElementsByTagName('head')[0];
  headTag.appendChild(h);
  headTag.appendChild(p);
  headTag.appendChild(w);
};

const AD_TRACKING_CLASS = 'ajc-ad-tracker';
const createAJCAdTracker = (ssaUrl, hidden = true) => {
  const styles = `width: 0; height: 0; border: 0; ${hidden ? 'display: none;' : ''}`;
  const element = document.createElement('script');
  element.type = 'text/javascript';
  element.classList.add(AD_TRACKING_CLASS);
  element.innerText = `
    var ssaUrl = 'https://' + '${ssaUrl}';
    new Image().src = ssaUrl;
    (function(d) {
      var syncUrl = 'https://' + 'pixel.programmatictrader.com/dmp/asyncPixelSync';
      var iframe = d.createElement('iframe');
      var frameElement = (iframe.frameElement || iframe);
      frameElement.style.cssText = "${styles}";
      frameElement.classList.add('${AD_TRACKING_CLASS}');
      iframe.src = "javascript:false";
      d.body.appendChild(iframe);
      var doc = iframe.contentWindow.document;
      doc.open().write('<body onload="window.location.href='+syncUrl+'">');
      doc.close();
    })(document);
  `;
  document.getElementsByTagName('body')[0].appendChild(element);
};

exports.onRouteUpdate = (state) => {
  const trackerElements = document.getElementsByClassName(AD_TRACKING_CLASS);
  // Remove any existing AJC Ad tracking elements.
  while (trackerElements[0]) {
    trackerElements[0].parentNode.removeChild(trackerElements[0]);
  }
  // Add this tracker to the homepage only.
  if (state.location.pathname === '/') {
    createAJCAdTracker('click.programmatictrader.com/conv/f93b4792ccf668ac', false);
  }
  createAJCAdTracker('pixel.programmatictrader.com/iap/c4daaeaa1ab5a45e');
};
